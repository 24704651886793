.video{
    width: 100%;
    height: 700px;
    padding-top: 10px;
}

.p{
    padding-bottom: 40px;
    padding-top: 40px;
}
