.pfacul {
    text-align: justify;
    font-size: 15pt;
}
.spnFcl{
    color:black;
}
.spnFcl:hover{
    color: red;
}
.FaculdadeHeader{

}