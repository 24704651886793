.PerfilHistorial{
    padding: 25px;

}
.PerfilHistorial p{
    font-size: 23px;
    text-align: justify;
}
.PerfilHistorial h2{
    font-size: 40px;
    text-decoration: underline;
}
.ImgStyle{
    height: max-content;
}