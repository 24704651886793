.Titulo{
    padding: 10px;
    font-size: 20px;
    text-align: center;
}

.BV{
    font-size: 30px;
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
    font-style:  italic;
    
}
.ContainerWelcome{
    padding-top: 50px;
}
.LogoImagem{
    text-align: center;
}