.ContainerNoticia {
    padding: 30px;
    display: flex;
    text-align: center;
    place-content: center;
}

.ContainerNoticia h3 {
    padding: 20px;
}

.ContainerNoticia img {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.ContainerNoticia p {
    text-align: justify;
}

.RowNot {
    display: flex;
    text-align: center;
    place-content: center;
}

.TextoNoticia {
    text-align: justify;
}

.NotDesc {
    text-align: center;
}

.NotDesc p {
    text-align: justify;
}

.ColDestaque {
    padding: 20px;
    text-align: center;
}

.DestaqueStyle {
    text-align: center;
}

.DestaqueStyle h1 {
    padding: 30px;
}

.DestaqueStyle h5 {
    padding: 10px;
    color: black;
    font-style: initial;
}

.DestaqueStyle h5:hover {
    color: rgb(255, 0, 0);
    font-style: initial;
}

.NotPag h1 {
    padding: 20px;
    text-align: center;
}

.NotGeral {
    text-align: center;
    padding: 10px;
    border: 5px solid rgb(175, 175, 175);
}

.NotGeral h5 {
    color: black;
}

.NotGeral h5:hover {
    color: rgb(241, 7, 7);
}


.noticia_contentor {
    background-color: #eee;
    margin: 10px 0;
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 20px;
    cursor: pointer;
}

.noticia_titulo {
    text-align: left;
    word-break: keep-all;
}
.noticia_corpo_mostrar{
    display: flex;
    gap: 10px;
}
.noticia_corpo {
    display: none;
}

.noticia_texto {
    text-align: justify;
}

.noticia_capa {
    max-width: 45vw;
    height: auto;
    border-radius: 2px;
}
.noticia_span{
    display: block;
    text-align: center;
    color: gray;
}
.noticia_span_ocultar{
    display: none;
}

@media (max-width: 768px) {
    .noticia_corpo_mostrar {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .noticia_texto p{
        word-wrap: break-word;
    }
    .noticia_capa {
        max-width: 100vw;
        order: -1;
    }
    
}