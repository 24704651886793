.PerfilReitoria{

}
.PerfilReitoria p{
    font-size: 23px;
    text-align: justify;
}
.PerfilReitoria h2{
    font-size: 40px;
    text-decoration: underline;
}
.PerfilReitoria h5{
    font-style: italic;
}
.ImgStyle{
    height: min-content;
}