.Links{
    display: flex;
    align-items: center; /* Alinhar verticalmente */
    justify-content: center;
    text-align: center;
    align-content: center;
    vertical-align: middle;
    padding: 30px;
}
.LinksTTL{
    text-align: center;
    align-content: center;
}
.Links h1{       
    padding: 30px;
}