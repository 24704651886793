.PoStyle{
    text-align: center;
}
.PoStyle a{
    color: black;
}
.PoStyle a:hover{
    color: rgb(255, 36, 36);
    font-size: 17px;
}
.PoStyle h1{
    padding: 20px;
}
.Header-Text{
    background-color: #52390b;
    color: white;
    font-family: Impact;
}

.Pos p{
    font-size: 18px;
    text-align: justify;
}
